<script lang="ts" setup>
import type { PackData, PackDataInfo } from '~/types/PackingList';
import sizeQuantity from './sizeQuantity.vue';
import type { SpecSheetAmount } from '~/types/SpecSheet';
import type { FormContext } from 'vee-validate';
import cloneDeep from 'lodash/cloneDeep';

// PROPS
interface Props {
    packData: PackData;
    index: number;
    totalPack: number;
    amountData: SpecSheetAmount[];
    form: FormContext;
}
const props = defineProps<Props>();
const { packData, form, index, totalPack, amountData } = toRefs(props);

type Emit = {
    change: [];
};
const emit = defineEmits<Emit>();

// FUNCTION
function formPath(path: string): string {
    return `packData[${index.value}].${path}`;
}

function sizeFormPath(index: number): string {
    return `${formPath('info')}[${index}]`;
}

function addSize() {
    const sizeData = cloneDeep(form.value.values.packData[index.value].info as PackDataInfo[]);

    sizeData.push({
        id: useGenVueKey(),
        amountGroupId: '',
        sizeId: '',
        sizeName: '',
        quantity: 1,
    });

    form.value.setFieldValue(formPath('info'), sizeData);
}

function deleteSize(sizeIndex: number) {
    const sizeData = cloneDeep(form.value.values.packData[index.value].info as PackDataInfo[]);
    sizeData.splice(sizeIndex, 1);
    form.value.setFieldValue(formPath('info'), sizeData);
    emit('change');
}

function deletePack() {
    const packData = cloneDeep(form.value.values.packData as PackData[]);
    packData.splice(index.value, 1);
    form.value.setFieldValue('packData', packData);
    emit('change');
}
</script>

<template>
    <div class="p-5 rounded-xl bg-white dark:bg-stone-800">
        <div class="flex flex-wrap items-center justify-between gap-5 mb-3">
            <div class="flex-1">
                <p class="text-stone-700 dark:text-white text-xl font-medium">Pack: {{ index + 1 }} / {{ totalPack }}</p>
                <p class="dark:text-stone-400">จำนวน: {{ packData.totalQuantity }} ตัว</p>
            </div>

            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>
                        <Button variant="secondaryLight" size="sm" class="!text-red-500 md:self-auto self-start" @click="deletePack()">
                            <IconCSS name="material-symbols:delete-outline-rounded" size="1.5rem" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>ลบแพ็ก</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>

        <div class="space-y-3">
            <div class="grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3">
                <sizeQuantity
                    v-for="(item, itemI) in packData.info"
                    :key="item.id"
                    v-model="packData.info"
                    :form-prefix="sizeFormPath(itemI)"
                    :amount-data="amountData"
                    :form="form"
                    @delete="deleteSize(itemI)"
                    @change="$emit('change')"
                />
                <Button class="w-full text-xl h-full" @click="addSize()">เพิ่มไซซ์</Button>
            </div>
        </div>
    </div>
</template>
