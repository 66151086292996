import * as yup from 'yup';
import type { GetAllApiMetadata, GetAllApiMetadataWithPaginate, GetApiMetadata, GetApiOptions, Paginate } from '~/types/Global';
import type { SpecSheet, SpecSheetPayload } from '~/types/SpecSheet';
import * as validateRule from '@/validators/specSheet';
import cloneDeep from 'lodash/cloneDeep';

const defaultGetApiMetadata: GetAllApiMetadata = { search: '', filter: {}, include: [], orderBy: {} };

const defaultGetApiOptions: GetApiOptions = {};

const BASE_URL = '/v1/spec-sheets';

export function useSpecSheet() {
    function getCreateValidateRule(productType: string) {
        const rules = cloneDeep(validateRule.baseCreateRule);
        switch (productType) {
            case 'เสื้อยืด':
            case 'เสื้อยืด Oversize':
            case 'เสื้อ Baby Tee':
            case 'เสื้อแขนกุด':
                rules.details = validateRule.tshirtDetailRule;
                break;
            case 'เสื้อฮู้ด':
                rules.details = validateRule.hoodieRule;
                break;
            case 'กระโปรง':
            case 'กางเกง':
                rules.details = validateRule.pantsDetailRule;
                break;
            case 'เสื้อโปโล':
                rules.details = validateRule.poloDetailRule;
                break;
            case 'เสื้อเชิ้ต':
                rules.details = validateRule.shirtDetailRule;
                break;
            case 'เสื้อสเวตเตอร์':
                rules.details = validateRule.sweaterDetailRule;
                break;
            case 'กระเป๋า':
                rules.details = validateRule.toteBagRule;
                break;
            default:
                rules.details = validateRule.defaultDetailRule;
                break;
        }

        if (productType.includes('หมวก')) {
            rules.details = validateRule.hatRule;
        }

        console.log(yup.object(rules));
        return yup.object(rules).required();
    }

    async function getAll(metadata: GetAllApiMetadata = defaultGetApiMetadata, options: GetApiOptions = defaultGetApiOptions): Promise<SpecSheet[]> {
        try {
            const { data, error } = await useApiFetch<SpecSheet[]>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return [];
        }
    }

    async function getAllByPaginate(metadata: GetAllApiMetadataWithPaginate, options: GetApiOptions = defaultGetApiOptions): Promise<Paginate<SpecSheet[]>> {
        try {
            const { data, error } = await useApiFetch<Paginate<SpecSheet[]>>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return { nextPage: 0, currentPage: 0, prevPage: 0, data: [] };
        }
    }

    async function getById(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<SpecSheet | null> {
        try {
            const { data, error } = await useApiFetch<SpecSheet>(`${BASE_URL}/${id}`, {
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function create(payload: Partial<SpecSheetPayload>, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<SpecSheet | null> {
        try {
            const { data, error } = await useApiFetch<SpecSheet>(`${BASE_URL}/create`, {
                method: 'POST',
                body: payload,
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function update(id: string, payload: Partial<SpecSheet>, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<SpecSheet | null> {
        try {
            const { data, error } = await useApiFetch<SpecSheet>(`${BASE_URL}/${id}`, {
                method: 'PATCH',
                body: payload,
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function voiding(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<SpecSheet | null> {
        try {
            const { data, error } = await useApiFetch<SpecSheet>(`${BASE_URL}/${id}/void`, {
                method: 'POST',
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function confirmed(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<SpecSheet | null> {
        try {
            const { data, error } = await useApiFetch<SpecSheet>(`${BASE_URL}/${id}/approved/manager`, {
                method: 'POST',
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function unConfirmed(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<SpecSheet | null> {
        try {
            const { data, error } = await useApiFetch<SpecSheet>(`${BASE_URL}/${id}/de-approved/manager`, {
                method: 'PATCH',
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    return {
        getAll,
        getAllByPaginate,
        getById,
        create,
        update,
        voiding,
        getCreateValidateRule,
        confirmed,
        unConfirmed,
    };
}
