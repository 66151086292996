<script lang="ts" setup>
import { debounce } from 'lodash';
import { updatePackDataValidator } from '~/validators/packingList';
import type { PackData, PackingList } from '~/types/PackingList';
import Pack from './Pack.vue';
import cloneDeep from 'lodash/cloneDeep';
import AddPackButton from './AddPackButton.vue';
import { useToast } from '~/components/ui/toast';

//PROPS
interface Props {
    packingList: PackingList;
}
const props = defineProps<Props>();
const { packingList } = toRefs(props);
const form = useForm({
    validationSchema: toTypedSchema(updatePackDataValidator),
    initialValues: packingList.value,
    keepValuesOnUnmount: true,
});

//EMIT
type Emit = {
    updated: [packingList: PackingList];
};
const emit = defineEmits<Emit>();

//VARIABLE
const packingListService = usePackingList();
const { toast } = useToast();
const savingToastId = ref<string>('');

//FUNCTION
function addPack() {
    const packData = cloneDeep(form.values.packData as PackData[]);

    packData.push({
        id: useGenVueKey(),
        info: [
            {
                id: useGenVueKey(),
                amountGroupId: '',
                sizeId: '',
                sizeName: '',
                quantity: 0,
            },
        ],
        totalQuantity: 0,
    });

    form.setFieldValue('packData', packData);
}

function setDataAfterUpdate(data: PackingList) {
    form.setFieldValue('totalQuantity', data.totalQuantity);

    form.setFieldValue(`packData` as any, data.packData);
}

function update() {
    form.handleSubmit(async (value) => {
        const data = await packingListService.update(value.id, value, { include: ['specSheet'] });
        if (data) {
            setDataAfterUpdate(data);
            toast({ title: 'บันทึกแล้ว', description: 'ท่านสามารถปิดแท็บนี้ได้แล้ว', icon: 'material-symbols:check-circle-rounded', iconClass: 'text-green-500' });
            emit('updated', data);
        }
    })();
}

const updateDebounced = () => {
    savingToastId.value = toast({ title: 'กำลังบันทึก', description: 'กรุณารอเพื่อให้ระบบบันทึกข้อมูล', icon: 'material-symbols:sync' }).id;
    debounce(update, 1000)();
};

function setNewPackingList(newPackingList: PackingList) {
    form.setValues(newPackingList);
}

// EXPOSE
defineExpose({
    setNewPackingList: setNewPackingList,
    update: update,
    form: form,
});
</script>

<template>
    <div>
        <div v-auto-animate class="space-y-5 mb-5">
            <Pack
                v-for="(pack, packI) in form.values.packData"
                :key="pack.id"
                :amount-data="packingList.amountData"
                :pack-data="pack"
                :index="packI"
                :total-pack="(form.values.packData || []).length"
                :form="form"
                @change="updateDebounced()"
            />
        </div>
        <AddPackButton class="w-full" @click="addPack()" />
    </div>
</template>
