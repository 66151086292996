import type { GetAllApiMetadata, GetAllApiMetadataWithPaginate, GetApiMetadata, GetApiOptions, Paginate } from '~/types/Global';
import type { PackingList, PackingListPayload, PackingListWithGroupPayload } from '~/types/PackingList';
import type { PackingListGroup } from '~/types/PackingListGroup';

const defaultGetApiMetadata: GetAllApiMetadata = { search: '', filter: {}, include: [], orderBy: {} };

const defaultGetApiOptions: GetApiOptions = {};

const BASE_URL = '/v1/packing-lists';

export function usePackingList() {
    async function getAll(metadata: GetAllApiMetadata = defaultGetApiMetadata, options: GetApiOptions = defaultGetApiOptions): Promise<PackingList[]> {
        try {
            const { data, error } = await useApiFetch<PackingList[]>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return [];
        }
    }

    async function getAllWithPaginate(metadata: GetAllApiMetadataWithPaginate, options: GetApiOptions = defaultGetApiOptions): Promise<Paginate<PackingList[]>> {
        try {
            const { data, error } = await useApiFetch<Paginate<PackingList[]>>(`${BASE_URL}/get`, {
                method: 'POST',
                body: metadata,
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return { data: [], prevPage: 0, currentPage: 0, nextPage: 0 };
        }
    }

    async function getPackingListGroup(specSheetId: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: GetApiOptions = defaultGetApiOptions) {
        try {
            const { data, error } = await useApiFetch<PackingListGroup[]>(`${BASE_URL}/group/${specSheetId}`, {
                method: 'GET',
                query: {
                    ...metadata,
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return [];
        }
    }

    async function getById(id?: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<PackingList | null> {
        try {
            const { data, error } = await useApiFetch<PackingList>(`${BASE_URL}/${id}`, {
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function create(payload: PackingListPayload, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<PackingList | null> {
        try {
            const { data, error } = await useApiFetch<PackingList>(`${BASE_URL}/create`, {
                method: 'POST',
                body: payload,
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function createWithGroup(
        payload: PackingListWithGroupPayload,
        metadata: GetApiMetadata = defaultGetApiMetadata,
        options: Pick<GetApiOptions, 'errorCallback'> = {},
    ): Promise<PackingList | null> {
        try {
            const { data, error } = await useApiFetch<PackingList>(`${BASE_URL}/create-with-group`, {
                method: 'POST',
                body: payload,
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function update(
        id: string,
        payload: Partial<PackingList>,
        metadata: GetApiMetadata = defaultGetApiMetadata,
        options: Pick<GetApiOptions, 'errorCallback'> = {},
    ): Promise<PackingList | null> {
        try {
            const { data, error } = await useApiFetch<PackingList>(`${BASE_URL}/${id}`, {
                method: 'PATCH',
                body: payload,
                query: {
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function deletePackingList(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<PackingList | null> {
        try {
            const { data, error } = await useApiFetch<PackingList>(`${BASE_URL}/${id}/delete`, {
                method: 'POST',
                query: {
                    ...metadata,
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    async function voidPackingList(id: string, metadata: GetApiMetadata = defaultGetApiMetadata, options: Pick<GetApiOptions, 'errorCallback'> = {}): Promise<PackingList | null> {
        try {
            const { data, error } = await useApiFetch<PackingList>(`${BASE_URL}/${id}/void`, {
                method: 'POST',
                query: {
                    ...metadata,
                    include: metadata.include?.join(','),
                },
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return null;
        }
    }

    return {
        getAll,
        getPackingListGroup,
        getById,
        update,
        getAllWithPaginate,
        create,
        createWithGroup,
        delete: deletePackingList,
        void: voidPackingList,
    };
}
