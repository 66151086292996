import { imageUrl as imageLoader } from '~/plugins/imageUrl';
import type { PackingList } from '~/types/PackingList';

// TYPE
interface PDFImagePayload {
    image: string;
    width: number | string;
}

interface PDFImageColumn {
    columns: PDFImagePayload[];
    columnGap: number;
    margin?: number[];
}

async function getBase64ImageFromUrl(url: string): Promise<string> {
    const { data, error } = await useApiFetch<string>(`/v1/files/base-64`, { method: 'POST', body: { url } });

    if (data.value && !error.value) {
        return data.value;
    }

    return '';
}

export default async (packingList: PackingList) => {
    const tableHeader = [
        { text: `ชื่อโปรเจค: ${packingList.specSheet?.name || '-'}`, colSpan: 3, bold: true, alignment: 'left' },
        { text: '' },
        { text: '' },
        { text: `วันที่ Pack `, colSpan: 1, rowSpan: 2, bold: true, alignment: 'center', margin: [0, 30, 0, 30] },
        { text: `Style : ${packingList.specSheet?.productType || '-'} `, colSpan: 1, rowSpan: 2, bold: true, alignment: 'center', margin: [0, 30, 0, 30] },
        { text: 'ผู้จัดส่ง', colSpan: 2, rowSpan: 2, bold: true, alignment: 'center', margin: [0, 30, 0, 30] },
        { text: '' },
    ];

    const columnName = [
        { text: 'หมายเลขถุง', bold: true, alignment: 'center' },
        { text: 'จำนวน', bold: true, alignment: 'center' },
        { text: 'Size', bold: true, alignment: 'center' },
        { text: 'วันที่', bold: true, alignment: 'center' },
        { text: 'ชื่อผู้ Pack', bold: true, alignment: 'center' },
        { text: 'วันที่', bold: true, alignment: 'center' },
        { text: 'ชื่อผู้ส่ง', bold: true, alignment: 'center' },
    ];

    const packingListAmount = [{ text: `จำนวนออเดอร์: ${packingList.totalQuantity}`, colSpan: 3, alignment: 'left' }, {}, {}, {}, {}, {}, {}];

    const packingListData = [
        ...packingList.packData.map((pack, i) => [
            { text: `${i + 1}`, alignment: 'center' },
            { text: `${pack.totalQuantity}`, alignment: 'center' },
            { text: pack.info.map((size) => `${size.sizeName} = ${size.quantity}`).join('\n'), alignment: 'center' },
            { text: '', alignment: 'center' },
            { text: '', alignment: 'center' },
            { text: '', alignment: 'center' },
            { text: '', alignment: 'center' },
        ]),
    ];

    const IMAGE_COLUMN_GAP = 10;
    const IMAGE_COLUMN_MARGIN: number[] = [0, 10, 0, 0];
    const imageColumns: PDFImageColumn[] = [
        {
            columns: [],
            columnGap: IMAGE_COLUMN_GAP,
            margin: IMAGE_COLUMN_MARGIN,
        },
    ];
    try {
        for (const img of packingList.img) {
            const lastColumnIndex = imageColumns.length - 1;

            const base64Image = await getBase64ImageFromUrl(imageLoader(img.url, 500));
            imageColumns[lastColumnIndex].columns.push({ image: base64Image, width: 150 });

            if (imageColumns[lastColumnIndex].columns.length === 3) {
                imageColumns.push({
                    columns: [],
                    columnGap: IMAGE_COLUMN_GAP,
                    margin: IMAGE_COLUMN_MARGIN,
                });
            }
        }
    } catch (err) {
        useBugsnag().notify(JSON.stringify(err));
        useBugsnag().notify(`CANT LOAD IMAGE IN PACKING LIST DOCS AT PACKING LIST ID: ${packingList.id}`);
    }

    return {
        content: [
            {
                text: 'Packing List',
                style: 'header',
            },
            {
                table: {
                    widths: [63, 50, 60, 70, 80, 70, 75],
                    body: [tableHeader, packingListAmount, columnName, ...packingListData],
                    marginBottom: 10,
                },
            },
            ...imageColumns,
        ],
        styles: {
            header: {
                fontSize: 20,
                bold: true,
                alignment: 'center',
            },
        },
        defaultStyle: {
            font: 'IBMPlexSansThai',
        },
    };
};
