<script lang="ts" setup>
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import type { PackingList } from '~/types/PackingList';
import DialogLoading from '~/components/ui/dialog/DialogLoading.vue';
import type { SpecSheet } from '~/types/SpecSheet';
import type { PackingListGroup } from '~/types/PackingListGroup';

// LAZY LOAD
const AddPackingListModal = defineAsyncComponent(() => import('../AddPackingListModal.vue'));
const EditPackingListModal = defineAsyncComponent(() => import('../EditPackingListModal.vue'));
const DeletePackingListModal = defineAsyncComponent(() => import('../DeletePackingListModal.vue'));

// PROPS
interface Props {
    specSheet: SpecSheet;
}
const props = defineProps<Props>();
const { specSheet } = toRefs(props);

// EMIT
type Emit = {
    select: [packingList: PackingList];
};
const emit = defineEmits<Emit>();

// MODAL STATE
const addPackingListModalState = ref<boolean>(false);
const editPackingListModalState = ref<boolean>(false);
const deletePackingListModalState = ref<boolean>(false);

// VARIABLE
const isLoading = ref<boolean>(true);
const packingListService = usePackingList();
const listOfPackingLists = ref<PackingListGroup[]>([]);
const activePackingList = ref<PackingList>({} as unknown as PackingList);

// FUNCTION
async function getListOfPackingList() {
    const data = await packingListService.getPackingListGroup(specSheet.value.id, { include: ['items.specSheet'] });

    // MANUAL FILTER
    for (const group of data) {
        group.items = (group.items || []).filter((packingList) => !packingList.isVoid);
    }

    listOfPackingLists.value = data || [];
}

function openAddPackingListModal() {
    addPackingListModalState.value = true;
}

async function refreshGroupList() {
    isLoading.value = true;
    await getListOfPackingList();

    isLoading.value = false;
}

function selectPackingList(packingList: PackingList) {
    emit('select', packingList);
    activePackingList.value = packingList;
}

function openEditPackingListModal(packingList: PackingList) {
    activePackingList.value = packingList;
    editPackingListModalState.value = true;
}

function openDeletePackingListModal(packingList: PackingList) {
    activePackingList.value = packingList;
    deletePackingListModalState.value = true;
}

onMounted(async () => {
    await getListOfPackingList();
    isLoading.value = false;
});
</script>

<template>
    <div class="bg-white dark:bg-stone-800 h-full p-4 rounded-xl space-y-3">
        <h1 class="text-black dark:text-white text-xl font-medium">รายการ Packing List</h1>

        <div v-if="!isLoading" class="space-y-3">
            <Collapsible v-for="group in listOfPackingLists" :key="group.id" :default-open="true" #="{ open }">
                <CollapsibleTrigger as-child>
                    <Button variant="secondaryLight" size="sm" class="text-md text-black dark:bg-stone-700 dark:text-white w-full p-3 rounded-xl justify-start">
                        <IconCSS name="material-symbols:chevron-right-rounded" class="transition mr-1" :class="{ 'rotate-90': open }" size="1.5rem" />
                        {{ group.name }}
                    </Button>
                </CollapsibleTrigger>

                <CollapsibleContent class="mt-3">
                    <div v-for="packingList of group.items || []" :key="packingList.id" class="flex">
                        <Button
                            variant="secondary-900"
                            size="sm"
                            class="text-md grow rounded-r-none font-medium text-black dark:text-white justify-start pl-12"
                            @click="selectPackingList(packingList)"
                            >{{ packingList.name }}</Button
                        >
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Button variant="secondary-900" size="sm" class="px-2 rounded-l-none">
                                    <IconCSS name="material-symbols:more-vert" size="1.5rem" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent class="w-44">
                                <DropdownMenuGroup>
                                    <DropdownMenuItem @click="openEditPackingListModal(packingList)">
                                        <IconCSS name="material-symbols:edit-outline-rounded" size="1.5rem" class="mr-1" />แก้ไข
                                    </DropdownMenuItem>
                                    <DropdownMenuItem as-child @click="openDeletePackingListModal(packingList)">
                                        <Button variant="destructive" class="w-full justify-start"><IconCSS name="material-symbols:delete-outline-rounded" size="1.5rem" class="mr-1" />ลบ</Button>
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>
        <div v-else class="flex justify-center py-3">
            <LoadingSpinner />
        </div>

        <Button class="w-full text-lg" @click="openAddPackingListModal()">เพิ่ม Packing Lists</Button>

        <Suspense v-if="addPackingListModalState">
            <AddPackingListModal v-model:open="addPackingListModalState" :spec-sheet="specSheet" @success="refreshGroupList()" />
            <template #fallback>
                <DialogLoading />
            </template>
        </Suspense>

        <Suspense v-if="editPackingListModalState">
            <EditPackingListModal v-model:open="editPackingListModalState" :packing-list="activePackingList" :spec-sheet="specSheet" @success="refreshGroupList()" />
            <template #fallback>
                <DialogLoading />
            </template>
        </Suspense>

        <Suspense v-if="deletePackingListModalState">
            <DeletePackingListModal v-model:open="deletePackingListModalState" :packing-list="activePackingList" @refresh="refreshGroupList()" />
            <template #fallback>
                <DialogLoading />
            </template>
        </Suspense>
    </div>
</template>
