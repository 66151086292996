import ValidationMessage from '~/helpers/validator/message';
import * as yup from 'yup';
import { DateTime } from 'luxon';

// CONSTANT
const sizeLabelRule = {
    sizeLabel: yup.string().required(ValidationMessage.required()),
    sizeLabelStyle: yup.string().when('sizeLabel', {
        is: (val: string) => val && val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.optional(),
    }),
    hangTag: yup.string().required(ValidationMessage.required()),
    sizeLabelNote: yup.string().optional(),
};

const fabricRule = {
    fabric: yup.string().required(ValidationMessage.required()),
    color: yup.string().required(ValidationMessage.required()),
};

// END CONSTANT

export const screenPointRule = {
    id: yup.string().required(ValidationMessage.required()),
    position: yup.string().required(ValidationMessage.required()),
    size: yup.string().required(ValidationMessage.required()),
    spaceFromCollar: yup.string().required(ValidationMessage.required()),
    type: yup.string().required(ValidationMessage.required()),
    color: yup.string().required(ValidationMessage.required()),
    file: yup.string().required(ValidationMessage.required()),
    note: yup.string().optional(),
};

export const screenPointGroup = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    artworks: yup.array(yup.object({})).min(1, 'ต้องใส่ภาพ'),
    positions: yup.array(yup.object(screenPointRule)),
};

export const pinPointRule = {
    id: yup.string().required(ValidationMessage.required()),
    position: yup.string().required(ValidationMessage.required()),
    type: yup.string().required(ValidationMessage.required()),
    size: yup.string().required(ValidationMessage.required()),
    spaceFromCollar: yup.string().required(ValidationMessage.required()),
    color: yup.string().required(ValidationMessage.required()),
    file: yup.string().required(ValidationMessage.required()),
    note: yup.string().optional(),
};

export const pinPointGroup = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    artworks: yup.array(yup.object({})).min(1, 'ต้องใส่ภาพ'),
    positions: yup.array(yup.object(pinPointRule)),
};

export const printPointRule = {
    id: yup.string().required(ValidationMessage.required()),
    position: yup.string().required(ValidationMessage.required()),
    size: yup.string().required(ValidationMessage.required()),
    spaceFromCollar: yup.string().required(ValidationMessage.required()),
    type: yup.string().required(ValidationMessage.required()),
    file: yup.string().required(ValidationMessage.required()),
    note: yup.string().optional(),
};

export const printPointGroup = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    artworks: yup.array(yup.object({})).min(1, 'ต้องใส่ภาพ'),
    positions: yup.array(yup.object(printPointRule)),
};

const baseSizeInfoInnerRule = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
};

export const sizeInfoInnerRule = {
    ...baseSizeInfoInnerRule,
    width: yup.number().min(1, ValidationMessage.minValue(1)).required(ValidationMessage.required()),
    long: yup.number().min(1, ValidationMessage.minValue(1)).required(ValidationMessage.required()),
};

export const sizeInfoInnerForPantsRule = {
    ...baseSizeInfoInnerRule,
    long: yup.number().min(1, ValidationMessage.minValue(1)).required(ValidationMessage.required()),
    waist: yup.number().min(1, ValidationMessage.minValue(1)).required(ValidationMessage.required()),
    hip: yup.number().min(1, ValidationMessage.minValue(1)).required(ValidationMessage.required()),
};

export const baseSizeInfoRule = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    templateName: yup.string().required(ValidationMessage.required()),
    note: yup.string().optional(),
};

export const sizeInfoRule = {
    ...baseSizeInfoRule,
    info: yup.array(yup.object(sizeInfoInnerRule)),
};

export const sizeInfoForPantsRule = {
    ...baseSizeInfoRule,
    info: yup.array(yup.object(sizeInfoInnerForPantsRule)),
};

export const amountInnerRule = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    amount: yup.number().min(1, ValidationMessage.minValue(1)).required(ValidationMessage.required()),
    unitName: yup.string().required(ValidationMessage.required()),
    unitMultiplier: yup.number().min(1, ValidationMessage.minValue(1)),
};

export const amountImgRule = {
    id: yup.string().required(ValidationMessage.required()),
    url: yup.string().required(ValidationMessage.required()),
};

export const amountRule = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    img: yup.array(yup.object(amountImgRule)),
    templateId: yup.string().required(ValidationMessage.required()),
    productId: yup.string().required(ValidationMessage.required()),
    info: yup.array().of(yup.object(amountInnerRule)),
    note: yup.string().optional(),
    type: yup.number().oneOf([1, 2], ValidationMessage.required()).required(ValidationMessage.required()),
    screenPointId: yup.string().optional(),
    pinPointId: yup.string().optional(),
    printPointId: yup.string().optional(),
};

export const armDetailRule = yup.object({
    ...fabricRule,
    sewStyleNote: yup.string().required(ValidationMessage.required()),
});

export const blanketDetailRule = yup.object({
    ...fabricRule,
    coverStitch: yup.string().required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const defaultDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    sewStyleNote: yup.string().optional().nullable(),
});

export const tshirtDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    frontNeck: yup.string().required(ValidationMessage.required()),
    backNeck: yup.string().required(ValidationMessage.required()),
    pocket: yup.string().required(ValidationMessage.required()),
    pocketStyle: yup.string().when('pocket', {
        is: (val: string) => !val || val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.optional(),
    }),
    collar: yup.string().required(ValidationMessage.required()),
    sleeve: yup.string().required(ValidationMessage.required()),
    neckRib: yup.number().min(0.01, ValidationMessage.minValue(0.01)).required(ValidationMessage.required()),
    coverStitch: yup.string().required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const shirtDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    sleeve: yup.string().required(ValidationMessage.required()),
    pocket: yup.string().required(ValidationMessage.required()),
    pocketStyle: yup.string().when('pocket', {
        is: (val: string) => !val || val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.optional(),
    }),
    sewStyleNote: yup.string().optional().nullable(),
});

export const sweaterDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    collar: yup.string().required(ValidationMessage.required()),
    sleeve: yup.string().required(ValidationMessage.required()),
    neckRib: yup.number().min(0.01, ValidationMessage.minValue(0.01)).required(ValidationMessage.required()),
    frontNeck: yup.string().required(ValidationMessage.required()),
    backNeck: yup.string().required(ValidationMessage.required()),
    coverStitch: yup.string().required(ValidationMessage.required()),
    pocket: yup.string().required(ValidationMessage.required()),
    pocketStyle: yup.string().when('pocket', {
        is: (val: string) => !val || val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.optional(),
    }),
    sewStyleNote: yup.string().optional().nullable(),
});

export const maskDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    maskStyle: yup.string().required(ValidationMessage.required()),
    maskStrap: yup.string().required(ValidationMessage.required()),
    maskStopper: yup.string().required(ValidationMessage.required()),
    maskLayer: yup.number().min(1, ValidationMessage.minValue(1)).required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const pantsDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    sizeTemplate: yup.string().required(ValidationMessage.required()),
    drawstring: yup.string().required(ValidationMessage.required()),
    drawstringColor: yup.string().required(ValidationMessage.required()),
    zipperColor: yup.string().required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const poloDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    sleeve: yup.string().required(ValidationMessage.required()),
    wovenCollar: yup.string().required(ValidationMessage.required()),
    wovenCollarStyle: yup.string().required(ValidationMessage.required()),
    wovenCollarSize: yup.string().required(ValidationMessage.required()),
    collar: yup.string().required(ValidationMessage.required()),
    backNeck: yup.string().required(ValidationMessage.required()),
    placket: yup.string().required(ValidationMessage.required()),
    placketSize: yup.string().required(ValidationMessage.required()),
    button: yup.number().min(0, ValidationMessage.minValue(0)).required(ValidationMessage.required()),
    buttonColor: yup.string().when('button', {
        is: (val: number) => val === undefined || val > 0,
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.optional(),
    }),
    coverStitch: yup.string().required(ValidationMessage.required()),
    pocket: yup.string().required(ValidationMessage.required()),
    pocketStyle: yup.string().when('pocket', {
        is: (val: string) => !val || val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.optional(),
    }),
    sewStyleNote: yup.string().optional().nullable(),
});

export const socksDetailRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    sockWidth: yup.number().min(0, ValidationMessage.minValue(0)).required(ValidationMessage.required()),
    sockLong: yup.number().min(0, ValidationMessage.minValue(0)).required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const toteBagRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    sizeTemplate: yup.string().required(ValidationMessage.required()),
    zipper: yup.string().required(ValidationMessage.required()),
    innerPocketSize: yup.string().required(ValidationMessage.required()),
    bottomPad: yup.string().required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const hatRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    sizeTemplate: yup.string().required(ValidationMessage.required()),
    fastener: yup.string().required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const hoodieRule = yup.object({
    ...sizeLabelRule,
    ...fabricRule,
    frontZipper: yup.string().required(ValidationMessage.required()),
    frontPocket: yup.string().required(ValidationMessage.required()),
    eyelet: yup.string().required(ValidationMessage.required()),
    drawstring: yup.string().required(ValidationMessage.required()),
    drawstringColor: yup.string().required(ValidationMessage.required()),
    sleeve: yup.string().required(ValidationMessage.required()),
    frontNeck: yup.string().required(ValidationMessage.required()),
    backNeck: yup.string().required(ValidationMessage.required()),
    sewStyleNote: yup.string().optional().nullable(),
});

export const packDetailsInnerRule = {
    from: yup.string().required(ValidationMessage.required()),
    file: yup.object({
        url: yup.string().optional(),
    }),
};

export const packDetailsInstructionule = {
    file: yup.object({
        url: yup.string().optional(),
    }),
};

export const packDetailsRule = {
    hangingLabel: yup.object(packDetailsInnerRule).required(ValidationMessage.required()),
    sticker: yup.object(packDetailsInnerRule).required(ValidationMessage.required()),
    packingBag: yup.object(packDetailsInnerRule).required(ValidationMessage.required()),
    packingInstruction: yup.object(packDetailsInstructionule).required(ValidationMessage.required()),
};

export const stuffingInstructionRule = {
    file: yup.object({
        url: yup.string().optional(),
    }),
};

export const stuffingDetailsRule = {
    instruction: yup.object(stuffingInstructionRule).required(ValidationMessage.required()),
};

export const baseCreateRule = {
    name: yup.string().required(ValidationMessage.required()),
    projectId: yup.string().required(ValidationMessage.required()),
    quotationId: yup.string().required(ValidationMessage.required()),
    productType: yup.string().required(ValidationMessage.required()),
    specSheetType: yup
        .array(yup.mixed().oneOf(['ORDER', 'SAMPLE', 'CLAIM']))
        .min(1, ValidationMessage.required())
        .required(ValidationMessage.required()),
    specialRequest: yup.string().optional(),
    details: yup.object({}),
    screenPoints: yup.array(yup.object(screenPointGroup)),
    pinPoints: yup.array(yup.object(pinPointGroup)),
    printPoints: yup.array(yup.object(printPointGroup)),
    sizeInfo: yup.array().when('productType', {
        is: (val: string) => val?.includes('กางเกง') || val?.includes('กระโปรง'),
        then: () => yup.array(yup.object(sizeInfoForPantsRule)),
        otherwise: () => yup.array(yup.object(sizeInfoRule)),
    }),
    isEasySpecSheet: yup.boolean().required(ValidationMessage.required()),
    amount: yup.array(yup.object(amountRule)),
    packing: yup.string().required(ValidationMessage.required()),
    advancedPacking: yup.boolean().required(ValidationMessage.required()),
    packingDetails: yup.object().when('advancedPacking', { is: (val: boolean) => val === true, then: () => yup.object(packDetailsRule).required(ValidationMessage.required()) }),
    packingNote: yup.string().optional(),
    stuffing: yup.string().required(ValidationMessage.required()),
    stuffingDetails: yup.object(stuffingDetailsRule).required(ValidationMessage.required()),
    stuffingNote: yup.string().optional(),
    isActive: yup.boolean(),
    img: yup.array(yup.object()),
    sampleType: yup.string().required(ValidationMessage.required()),
    sampleConfirmedType: yup.string().when('sampleType', {
        is: (val: string) => val && val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.nullable(),
    }),
    sampleConfirmedTime: yup.string().when('sampleType', {
        is: (val: string) => val && val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.nullable(),
    }),
    sampleDue: yup.date().when('sampleType', {
        is: (val: string) => val && val !== 'ไม่มี',
        then: (schema) => schema.required(ValidationMessage.required()).min(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(), 'ต้องเป็นวันตั้งแต่วันนี้เป็นต้นไป'),
        otherwise: (schema) => schema.nullable(),
    }),
};

export const specSheetDraftRule = yup.object({
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    timestamp: yup.string().required(ValidationMessage.required()),
    data: yup.object().required(ValidationMessage.required()),
});
