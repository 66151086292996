class ValidationMessage {
    public static required(): string {
        return `จำเป็นต้องกรอก`;
    }

    public static minLength(min: string | number): string {
        return `ต้องมีข้อความอย่างน้อย ${min} ตัว`;
    }

    public static maxLength(max: string | number): string {
        return `มีข้อความได้มากสุด ${max} ตัว`;
    }

    public static minValue(max: string | number): string {
        return `มีค่าได้น้อยสุด ${max}`;
    }

    public static maxValue(max: string | number): string {
        return `มีค่าได้มากสุด ${max}`;
    }

    public static integer(): string {
        return `ต้องเป็นจำนวนเต็มเท่านั้น`;
    }

    public static decimal(): string {
        return `ต้องเป็นตัวเลขเท่านั้น`;
    }

    public static email(): string {
        return `ต้องเป็นอีเมลเท่านั้น`;
    }

    public static sameAs(prefix: string = ''): string {
        return `${prefix}ต้องเหมือนกัน`;
    }

    public static numeric() {
        return `ต้องเป็นตัวเลขเท่านั้น`;
    }

    public static alpha() {
        return `ต้องเป็นตัวอักษรเท่านั้น`;
    }

    public required(): string {
        return `จำเป็นต้องกรอก`;
    }

    public minLength(min: string | number): string {
        return `ต้องมีข้อความอย่างน้อย ${min} ตัว`;
    }

    public maxLength(max: string | number): string {
        return `มีข้อความได้มากสุด ${max} ตัว`;
    }

    public minValue(max: string | number): string {
        return `มีค่าได้น้อยสุด ${max}`;
    }

    public maxValue(max: string | number): string {
        return `มีค่าได้มากสุด ${max}`;
    }

    public integer(): string {
        return `ต้องเป็นจำนวนเต็มเท่านั้น`;
    }

    public decimal(): string {
        return `ต้องเป็นตัวเลขเท่านั้น`;
    }

    public email(): string {
        return `ต้องเป็นอีเมลเท่านั้น`;
    }

    public sameAs(prefix: string = ''): string {
        return `${prefix}ต้องเหมือนกัน`;
    }

    public numeric() {
        return `ต้องเป็นตัวเลขเท่านั้น`;
    }

    public alpha() {
        return `ต้องเป็นตัวอักษรเท่านั้น`;
    }
}
export default ValidationMessage;
