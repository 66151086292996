import type { PackingList } from '~/types/PackingList';

export default (packingList: PackingList) => ({
    pageSize: { width: 283.465, height: 283.465 },
    content: [
        ...packingList.packData.map((pack, index) => ({
            stack: [
                { text: `Pack : ${index + 1}/${packingList.packData.length}`, bold: true, fontSize: 20 },
                {
                    text: [
                        { text: 'Project: ', bold: true, fontSize: 20 },
                        { text: packingList.specSheet?.name, bold: true, fontSize: 12 },
                    ],
                },
                { text: `จำนวน : ${pack.totalQuantity}`, bold: true, fontSize: 20 },
                {
                    text: pack.info.map((size) => `${size.sizeName} = ${size.quantity}`).join('\n'),
                    alignment: 'center',
                    bold: true,
                    fontSize: 14,
                },
                { text: 'ผู้แพ็ค : วันที่แพ็ค :...........', bold: true, fontSize: 20, margin: [0, 20, 0, 0] },
            ],
            pageBreak: index + 1 < packingList.packData.length ? 'after' : '',
        })),
    ],
    pageMargins: [5, 5, 5, 5],
    styles: {
        header: {
            fontSize: 20,
            bold: true,
            alignment: 'center',
        },
    },
    defaultStyle: {
        font: 'IBMPlexSansThai',
    },
});
