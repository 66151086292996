<script setup lang="ts">
import { isVNode } from 'vue';
import { useToast } from './use-toast';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '.';

const { toasts } = useToast();
</script>

<template>
    <ToastProvider>
        <Toast v-for="toast in toasts" :key="toast.id" v-bind="toast">
            <div class="flex gap-3">
                <IconCSS v-if="toast.icon" size="3rem" :class="toast.iconClass || ''" :name="toast.icon" />
                <div>
                    <ToastTitle v-if="toast.title">
                        {{ toast.title }}
                    </ToastTitle>
                    <template v-if="toast.description">
                        <ToastDescription v-if="isVNode(toast.description)">
                            <component :is="toast.description" />
                        </ToastDescription>
                        <ToastDescription v-else>
                            {{ toast.description }}
                        </ToastDescription>
                    </template>
                    <ToastClose />
                </div>
            </div>
            <component :is="toast.action" />
        </Toast>
        <ToastViewport />
    </ToastProvider>
</template>
