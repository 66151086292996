<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { ToastDescription, type ToastDescriptionProps } from 'radix-vue';
import { cn } from '@/lib/utils';

const props = defineProps<ToastDescriptionProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>

<template>
    <ToastDescription :class="cn('text-sm text-stone-500 dark:text-stone-400', props.class)" v-bind="delegatedProps">
        <slot />
    </ToastDescription>
</template>
