import { default as chart1phuuwaxvCMeta } from "/home/runner/work/texcel-web/texcel-web/pages/accounts/chart.vue?macro=true";
import { default as indexW11m58DNhRMeta } from "/home/runner/work/texcel-web/texcel-web/pages/index.vue?macro=true";
import { default as loginUDIwc2RKtSMeta } from "/home/runner/work/texcel-web/texcel-web/pages/login.vue?macro=true";
import { default as _5_45polo_45checkDcpxIvoodMMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/mm/5-polo-check.vue?macro=true";
import { default as _5_45round_45collar_45check9Xer7zpKSpMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/mm/5-round-collar-check.vue?macro=true";
import { default as _5_45polo_45checkh4laaXsc3XMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th-mm/5-polo-check.vue?macro=true";
import { default as _5_45round_45collar_45check3I7eL1ug0xMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th-mm/5-round-collar-check.vue?macro=true";
import { default as _2_45screen_45each_45size_45checkDPIFDlOcRTMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/2-screen-each-size-check.vue?macro=true";
import { default as _5_45bag_45check3vDBtG3hx7Meta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-bag-check.vue?macro=true";
import { default as _5_45polo_45check56blU9zzC2Meta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-polo-check.vue?macro=true";
import { default as _5_45round_45collar_45checkpxUvbbvTW9Meta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-round-collar-check.vue?macro=true";
import { default as _5_45work_45checkBweB7rVQgYMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-work-check.vue?macro=true";
import { default as control_45bag_45checkg9TjXbzpseMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/control-bag-check.vue?macro=true";
import { default as cut_45check_45sheetVbmnizPgwFMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/cut-check-sheet.vue?macro=true";
import { default as dft_45check_45sheet7u3CBQZBp0Meta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/dft-check-sheet.vue?macro=true";
import { default as document_45checksEEPy6FwejMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/document-check.vue?macro=true";
import { default as packing_45list_45bagh0YdMfLTI6Meta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/packing-list-bag.vue?macro=true";
import { default as packing_45list_45sheet3F2tYixBQTMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/packing-list-sheet.vue?macro=true";
import { default as production_45formpFgBzaBooqMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/production-form.vue?macro=true";
import { default as screen_45check_45sheetm8MU4DjirBMeta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/screen-check-sheet.vue?macro=true";
import { default as sew_45check_45sheetHeaBt8gAX7Meta } from "/home/runner/work/texcel-web/texcel-web/pages/pdf/th/sew-check-sheet.vue?macro=true";
import { default as indexWwphBpa9z9Meta } from "/home/runner/work/texcel-web/texcel-web/pages/product/planning/index.vue?macro=true";
import { default as addJa6l8zj02ZMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/bom/add.vue?macro=true";
import { default as indexpWay9siatzMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/bom/index.vue?macro=true";
import { default as index7fUaPStSpsMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/packing-list/index.vue?macro=true";
import { default as viewxTSH0n2hKHMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/packing-list/view.vue?macro=true";
import { default as addLNLjMcBSGqMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/add.vue?macro=true";
import { default as editd3tgpwQw2VMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/edit.vue?macro=true";
import { default as indexaPvoDllbdvMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/index.vue?macro=true";
import { default as viewFMNCl0LSoxMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/view.vue?macro=true";
import { default as addZLzE8bN178Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/sam/add.vue?macro=true";
import { default as indexyI3NURuIYmMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/sam/index.vue?macro=true";
import { default as ChangeImageModalDjgmOScFazMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ChangeImageModal.vue?macro=true";
import { default as DefaultFooterrDTeJwDSg2Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/DefaultFooter.vue?macro=true";
import { default as DropdownItemAwE85TylddMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/DropdownItem.vue?macro=true";
import { default as ImageSizeInputPeLf39OYbNMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ImageSizeInput.vue?macro=true";
import { default as PinPointTableGskhbd9R2FMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/PinPointTable.vue?macro=true";
import { default as ProjectDetailTableP7mibZpmEVMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ProjectDetailTable.vue?macro=true";
import { default as RowInputxBfdlfXjj1Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/RowInput.vue?macro=true";
import { default as ScreenPointTablegheWx3Zgk3Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ScreenPointTable.vue?macro=true";
import { default as SelectImagegCbZXBHOV0Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/SelectImage.vue?macro=true";
import { default as SizeGroupTableHomApS8L5rMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/SizeGroupTable.vue?macro=true";
import { default as createcpuKNMNFwTMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/create.vue?macro=true";
import { default as editENEEBM4RqaMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/edit.vue?macro=true";
import { default as index1yaG9FCSXfMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/index.vue?macro=true";
import { default as _2_45screen_45each_45size_45checkFFDqy4b7bSMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/2-screen-each-size-check.vue?macro=true";
import { default as _5_45bag_45checkJe1kCyQ0ypMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-bag-check.vue?macro=true";
import { default as _5_45polo_45checkKAy1qSKfBpMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-polo-check.vue?macro=true";
import { default as _5_45round_45collar_45checkbmtSwVeNDKMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-round-collar-check.vue?macro=true";
import { default as _5_45work_45checkexw7GkHE6BMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-work-check.vue?macro=true";
import { default as cut_45check_45sheetVl1AMrmYk3Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/cut-check-sheet.vue?macro=true";
import { default as indexvYUifneLiyMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/cutting-report/index.vue?macro=true";
import { default as dft_45check_45sheetkJRrEcooMWMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/dft-check-sheet.vue?macro=true";
import { default as document_45checkSj3WiDk0XJMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/document-check.vue?macro=true";
import { default as indexYt9BUmO2KVMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/index.vue?macro=true";
import { default as indexeJCFSc0XZ7Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/koomad/index.vue?macro=true";
import { default as index6wKrBXEgOIMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/pin/index.vue?macro=true";
import { default as printkcsKXoAxwaMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/print.vue?macro=true";
import { default as production_45formaJ9oEDshM6Meta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/production-form.vue?macro=true";
import { default as index30oNGsdgQqMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/record-of-sew/index.vue?macro=true";
import { default as screen_45check_45sheetNkcSqFgtrqMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/screen-check-sheet.vue?macro=true";
import { default as indexVCyJJBBmfdMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/screen/index.vue?macro=true";
import { default as sew_45check_45sheetr2EX8DYkpBMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/sew-check-sheet.vue?macro=true";
import { default as indexXnlSIbSm8nMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/sew/index.vue?macro=true";
import { default as indexeX7oZiHGokMeta } from "/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/sublimation/index.vue?macro=true";
import { default as addgMYIBYtw24Meta } from "/home/runner/work/texcel-web/texcel-web/pages/project/add.vue?macro=true";
import { default as editQclSkqeOw6Meta } from "/home/runner/work/texcel-web/texcel-web/pages/project/edit.vue?macro=true";
import { default as indexXBMEXeCBgyMeta } from "/home/runner/work/texcel-web/texcel-web/pages/project/index.vue?macro=true";
import { default as viewyDWZh1LKIEMeta } from "/home/runner/work/texcel-web/texcel-web/pages/project/view.vue?macro=true";
import { default as addUpO5gp7dRKMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/add.vue?macro=true";
import { default as editLiGGUNSBynMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/edit.vue?macro=true";
import { default as index3c3EUItXNtMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/index.vue?macro=true";
import { default as viewEcFltEdegcMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/view.vue?macro=true";
import { default as addlo5HPFfURfMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/add.vue?macro=true";
import { default as edit58qS2IgpnLMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/edit.vue?macro=true";
import { default as index2QhKnHWmF9Meta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/index.vue?macro=true";
import { default as viewqZ3Gk3EsOwMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/view.vue?macro=true";
import { default as addXcuLAgYBgjMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/add.vue?macro=true";
import { default as editeVR5RbMbvWMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/edit.vue?macro=true";
import { default as index5wBsuWhTNIMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/index.vue?macro=true";
import { default as viewbvik7ZP5WgMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/view.vue?macro=true";
import { default as addxTaATomEYCMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/add.vue?macro=true";
import { default as edittmjY1bg5ZqMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/edit.vue?macro=true";
import { default as indexB5bZ886hyPMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/index.vue?macro=true";
import { default as viewcmAP6mj09IMeta } from "/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/view.vue?macro=true";
import { default as addaKRYZxSs3HMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/calculator/add.vue?macro=true";
import { default as indexgINusafPObMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/calculator/index.vue?macro=true";
import { default as addCz8bVRTW5HMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/customer/add.vue?macro=true";
import { default as edit7QZunEpjYaMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/customer/edit.vue?macro=true";
import { default as indexv88eoB4XaTMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/customer/index.vue?macro=true";
import { default as viewCRmsn1hJ6qMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/customer/view.vue?macro=true";
import { default as addrOLPEAaiv3Meta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/add.vue?macro=true";
import { default as editvTh8SxbXWYMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/edit.vue?macro=true";
import { default as indexx7IR4hyYHUMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/index.vue?macro=true";
import { default as viewDgQVVcxZjiMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/view.vue?macro=true";
import { default as addxJjl4OmqmjMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/add.vue?macro=true";
import { default as editV2nmlAhfF9Meta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/edit.vue?macro=true";
import { default as indexz4G3ZklhSkMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/index.vue?macro=true";
import { default as viewY3N2dDDjvEMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/view.vue?macro=true";
import { default as add8q6CXubLhOMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/add.vue?macro=true";
import { default as edit1HSnSjNDbQMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/edit.vue?macro=true";
import { default as indexb9cK3QAkz2Meta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/index.vue?macro=true";
import { default as indext3e5kH4DVGMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/view/index.vue?macro=true";
import { default as printmVutMDYM0WMeta } from "/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/view/print.vue?macro=true";
import { default as indexvFlbh6X103Meta } from "/home/runner/work/texcel-web/texcel-web/pages/settings/index.vue?macro=true";
import { default as departmentWnO3AmihTUMeta } from "/home/runner/work/texcel-web/texcel-web/pages/settings/system/department.vue?macro=true";
import { default as dropdown_45data4rzEE1yc8hMeta } from "/home/runner/work/texcel-web/texcel-web/pages/settings/system/dropdown-data.vue?macro=true";
import { default as prod_45users8D8U31jrx2Meta } from "/home/runner/work/texcel-web/texcel-web/pages/settings/system/prod-users.vue?macro=true";
import { default as setting_45systemrp9BH1Q2FQMeta } from "/home/runner/work/texcel-web/texcel-web/pages/settings/system/setting-system.vue?macro=true";
import { default as usersVyxJ905YjoMeta } from "/home/runner/work/texcel-web/texcel-web/pages/settings/system/users.vue?macro=true";
import { default as addyEu2cO9OcmMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/add.vue?macro=true";
import { default as editrhlZiclpQmMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/edit.vue?macro=true";
import { default as indexnOYlJCzP8QMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/index.vue?macro=true";
import { default as viewNDxV2tpdJoMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/view.vue?macro=true";
import { default as addXLs837VHI1Meta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/add.vue?macro=true";
import { default as editKUBstGUZJKMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/edit.vue?macro=true";
import { default as index8IpgGAfSM9Meta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/index.vue?macro=true";
import { default as viewoiBXPRSPW6Meta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/view.vue?macro=true";
import { default as addUTOfZDP8tbMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-request/add.vue?macro=true";
import { default as editHHM1XPknMjMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-request/edit.vue?macro=true";
import { default as indexrbSRNwvLzFMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-request/index.vue?macro=true";
import { default as viewGOSFnx5zISMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-request/view.vue?macro=true";
import { default as addTOf9JVzYLmMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/add.vue?macro=true";
import { default as edit7z7UOFhCktMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/edit.vue?macro=true";
import { default as indexJvfs2ZbtNPMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/index.vue?macro=true";
import { default as viewoulj2IstuwMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/view.vue?macro=true";
import { default as addP3udjEcyQOMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/add.vue?macro=true";
import { default as editRWrEzRq5yKMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/edit.vue?macro=true";
import { default as indexRZ0X45vzLiMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/index.vue?macro=true";
import { default as viewOQk9fThjBbMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/view.vue?macro=true";
import { default as indexAZ8YodfQqSMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/product-category/index.vue?macro=true";
import { default as addiJ6tfTH7NbMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/product/add.vue?macro=true";
import { default as indexXZq1szYcjgMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/product/index.vue?macro=true";
import { default as view8VDt1OhKjtMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/product/view.vue?macro=true";
import { default as add084IMo5430Meta } from "/home/runner/work/texcel-web/texcel-web/pages/store/recieve-product/add.vue?macro=true";
import { default as indexdpogRI9kU5Meta } from "/home/runner/work/texcel-web/texcel-web/pages/store/recieve-product/index.vue?macro=true";
import { default as viewyJ1lyQVrLkMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/recieve-product/view.vue?macro=true";
import { default as indexkdwAllAMpXMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/store-type/index.vue?macro=true";
import { default as addwHEywFhJqaMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/wareHouse/add.vue?macro=true";
import { default as indexydZyMBxuBZMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/wareHouse/index.vue?macro=true";
import { default as viewBJEouXQ6eUMeta } from "/home/runner/work/texcel-web/texcel-web/pages/store/wareHouse/view.vue?macro=true";
export default [
  {
    name: "accounts-chart",
    path: "/accounts/chart",
    meta: chart1phuuwaxvCMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/accounts/chart.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginUDIwc2RKtSMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/login.vue")
  },
  {
    name: "pdf-mm-5-polo-check",
    path: "/pdf/mm/5-polo-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/mm/5-polo-check.vue")
  },
  {
    name: "pdf-mm-5-round-collar-check",
    path: "/pdf/mm/5-round-collar-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/mm/5-round-collar-check.vue")
  },
  {
    name: "pdf-th-mm-5-polo-check",
    path: "/pdf/th-mm/5-polo-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th-mm/5-polo-check.vue")
  },
  {
    name: "pdf-th-mm-5-round-collar-check",
    path: "/pdf/th-mm/5-round-collar-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th-mm/5-round-collar-check.vue")
  },
  {
    name: "pdf-th-2-screen-each-size-check",
    path: "/pdf/th/2-screen-each-size-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/2-screen-each-size-check.vue")
  },
  {
    name: "pdf-th-5-bag-check",
    path: "/pdf/th/5-bag-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-bag-check.vue")
  },
  {
    name: "pdf-th-5-polo-check",
    path: "/pdf/th/5-polo-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-polo-check.vue")
  },
  {
    name: "pdf-th-5-round-collar-check",
    path: "/pdf/th/5-round-collar-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-round-collar-check.vue")
  },
  {
    name: "pdf-th-5-work-check",
    path: "/pdf/th/5-work-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/5-work-check.vue")
  },
  {
    name: "pdf-th-control-bag-check",
    path: "/pdf/th/control-bag-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/control-bag-check.vue")
  },
  {
    name: "pdf-th-cut-check-sheet",
    path: "/pdf/th/cut-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/cut-check-sheet.vue")
  },
  {
    name: "pdf-th-dft-check-sheet",
    path: "/pdf/th/dft-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/dft-check-sheet.vue")
  },
  {
    name: "pdf-th-document-check",
    path: "/pdf/th/document-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/document-check.vue")
  },
  {
    name: "pdf-th-packing-list-bag",
    path: "/pdf/th/packing-list-bag",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/packing-list-bag.vue")
  },
  {
    name: "pdf-th-packing-list-sheet",
    path: "/pdf/th/packing-list-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/packing-list-sheet.vue")
  },
  {
    name: "pdf-th-production-form",
    path: "/pdf/th/production-form",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/production-form.vue")
  },
  {
    name: "pdf-th-screen-check-sheet",
    path: "/pdf/th/screen-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/screen-check-sheet.vue")
  },
  {
    name: "pdf-th-sew-check-sheet",
    path: "/pdf/th/sew-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/pdf/th/sew-check-sheet.vue")
  },
  {
    name: "product-planning",
    path: "/product/planning",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/product/planning/index.vue")
  },
  {
    name: "production-bom-add",
    path: "/production/bom/add",
    meta: addJa6l8zj02ZMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/bom/add.vue")
  },
  {
    name: "production-bom",
    path: "/production/bom",
    meta: indexpWay9siatzMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/bom/index.vue")
  },
  {
    name: "production-packing-list",
    path: "/production/packing-list",
    meta: index7fUaPStSpsMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/packing-list/index.vue")
  },
  {
    name: "production-packing-list-view",
    path: "/production/packing-list/view",
    meta: viewxTSH0n2hKHMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/packing-list/view.vue")
  },
  {
    name: "production-reserve-material-add",
    path: "/production/reserve-material/add",
    meta: addLNLjMcBSGqMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/add.vue")
  },
  {
    name: "production-reserve-material-edit",
    path: "/production/reserve-material/edit",
    meta: editd3tgpwQw2VMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/edit.vue")
  },
  {
    name: "production-reserve-material",
    path: "/production/reserve-material",
    meta: indexaPvoDllbdvMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/index.vue")
  },
  {
    name: "production-reserve-material-view",
    path: "/production/reserve-material/view",
    meta: viewFMNCl0LSoxMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/reserve-material/view.vue")
  },
  {
    name: "production-sam-add",
    path: "/production/sam/add",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/sam/add.vue")
  },
  {
    name: "production-sam",
    path: "/production/sam",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/sam/index.vue")
  },
  {
    name: "production-work-order-components-ChangeImageModal",
    path: "/production/work-order/components/ChangeImageModal",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ChangeImageModal.vue")
  },
  {
    name: "production-work-order-components-DefaultFooter",
    path: "/production/work-order/components/DefaultFooter",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/DefaultFooter.vue")
  },
  {
    name: "production-work-order-components-DropdownItem",
    path: "/production/work-order/components/DropdownItem",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/DropdownItem.vue")
  },
  {
    name: "production-work-order-components-ImageSizeInput",
    path: "/production/work-order/components/ImageSizeInput",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ImageSizeInput.vue")
  },
  {
    name: "production-work-order-components-PinPointTable",
    path: "/production/work-order/components/PinPointTable",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/PinPointTable.vue")
  },
  {
    name: "production-work-order-components-ProjectDetailTable",
    path: "/production/work-order/components/ProjectDetailTable",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ProjectDetailTable.vue")
  },
  {
    name: "production-work-order-components-RowInput",
    path: "/production/work-order/components/RowInput",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/RowInput.vue")
  },
  {
    name: "production-work-order-components-ScreenPointTable",
    path: "/production/work-order/components/ScreenPointTable",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/ScreenPointTable.vue")
  },
  {
    name: "production-work-order-components-SelectImage",
    path: "/production/work-order/components/SelectImage",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/SelectImage.vue")
  },
  {
    name: "production-work-order-components-SizeGroupTable",
    path: "/production/work-order/components/SizeGroupTable",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/components/SizeGroupTable.vue")
  },
  {
    name: "production-work-order-create",
    path: "/production/work-order/create",
    meta: createcpuKNMNFwTMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/create.vue")
  },
  {
    name: "production-work-order-edit",
    path: "/production/work-order/edit",
    meta: editENEEBM4RqaMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/edit.vue")
  },
  {
    name: "production-work-order",
    path: "/production/work-order",
    meta: index1yaG9FCSXfMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/index.vue")
  },
  {
    name: "production-work-order-view-2-screen-each-size-check",
    path: "/production/work-order/view/2-screen-each-size-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/2-screen-each-size-check.vue")
  },
  {
    name: "production-work-order-view-5-bag-check",
    path: "/production/work-order/view/5-bag-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-bag-check.vue")
  },
  {
    name: "production-work-order-view-5-polo-check",
    path: "/production/work-order/view/5-polo-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-polo-check.vue")
  },
  {
    name: "production-work-order-view-5-round-collar-check",
    path: "/production/work-order/view/5-round-collar-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-round-collar-check.vue")
  },
  {
    name: "production-work-order-view-5-work-check",
    path: "/production/work-order/view/5-work-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/5-work-check.vue")
  },
  {
    name: "production-work-order-view-cut-check-sheet",
    path: "/production/work-order/view/cut-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/cut-check-sheet.vue")
  },
  {
    name: "production-work-order-view-cutting-report",
    path: "/production/work-order/view/cutting-report",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/cutting-report/index.vue")
  },
  {
    name: "production-work-order-view-dft-check-sheet",
    path: "/production/work-order/view/dft-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/dft-check-sheet.vue")
  },
  {
    name: "production-work-order-view-document-check",
    path: "/production/work-order/view/document-check",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/document-check.vue")
  },
  {
    name: "production-work-order-view",
    path: "/production/work-order/view",
    meta: indexYt9BUmO2KVMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/index.vue")
  },
  {
    name: "production-work-order-view-koomad",
    path: "/production/work-order/view/koomad",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/koomad/index.vue")
  },
  {
    name: "production-work-order-view-pin",
    path: "/production/work-order/view/pin",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/pin/index.vue")
  },
  {
    name: "production-work-order-view-print",
    path: "/production/work-order/view/print",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/print.vue")
  },
  {
    name: "production-work-order-view-production-form",
    path: "/production/work-order/view/production-form",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/production-form.vue")
  },
  {
    name: "production-work-order-view-record-of-sew",
    path: "/production/work-order/view/record-of-sew",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/record-of-sew/index.vue")
  },
  {
    name: "production-work-order-view-screen-check-sheet",
    path: "/production/work-order/view/screen-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/screen-check-sheet.vue")
  },
  {
    name: "production-work-order-view-screen",
    path: "/production/work-order/view/screen",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/screen/index.vue")
  },
  {
    name: "production-work-order-view-sew-check-sheet",
    path: "/production/work-order/view/sew-check-sheet",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/sew-check-sheet.vue")
  },
  {
    name: "production-work-order-view-sew",
    path: "/production/work-order/view/sew",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/sew/index.vue")
  },
  {
    name: "production-work-order-view-sublimation",
    path: "/production/work-order/view/sublimation",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/production/work-order/view/sublimation/index.vue")
  },
  {
    name: "project-add",
    path: "/project/add",
    meta: addgMYIBYtw24Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/project/add.vue")
  },
  {
    name: "project-edit",
    path: "/project/edit",
    meta: editQclSkqeOw6Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/project/edit.vue")
  },
  {
    name: "project",
    path: "/project",
    meta: indexXBMEXeCBgyMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/project/index.vue")
  },
  {
    name: "project-view",
    path: "/project/view",
    meta: viewyDWZh1LKIEMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/project/view.vue")
  },
  {
    name: "purchases-goods-receive-add",
    path: "/purchases/goods-receive/add",
    meta: addUpO5gp7dRKMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/add.vue")
  },
  {
    name: "purchases-goods-receive-edit",
    path: "/purchases/goods-receive/edit",
    meta: editLiGGUNSBynMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/edit.vue")
  },
  {
    name: "purchases-goods-receive",
    path: "/purchases/goods-receive",
    meta: index3c3EUItXNtMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/index.vue")
  },
  {
    name: "purchases-goods-receive-view",
    path: "/purchases/goods-receive/view",
    meta: viewEcFltEdegcMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/goods-receive/view.vue")
  },
  {
    name: "purchases-purchase-order-add",
    path: "/purchases/purchase-order/add",
    meta: addlo5HPFfURfMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/add.vue")
  },
  {
    name: "purchases-purchase-order-edit",
    path: "/purchases/purchase-order/edit",
    meta: edit58qS2IgpnLMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/edit.vue")
  },
  {
    name: "purchases-purchase-order",
    path: "/purchases/purchase-order",
    meta: index2QhKnHWmF9Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/index.vue")
  },
  {
    name: "purchases-purchase-order-view",
    path: "/purchases/purchase-order/view",
    meta: viewqZ3Gk3EsOwMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-order/view.vue")
  },
  {
    name: "purchases-purchase-requisition-add",
    path: "/purchases/purchase-requisition/add",
    meta: addXcuLAgYBgjMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/add.vue")
  },
  {
    name: "purchases-purchase-requisition-edit",
    path: "/purchases/purchase-requisition/edit",
    meta: editeVR5RbMbvWMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/edit.vue")
  },
  {
    name: "purchases-purchase-requisition",
    path: "/purchases/purchase-requisition",
    meta: index5wBsuWhTNIMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/index.vue")
  },
  {
    name: "purchases-purchase-requisition-view",
    path: "/purchases/purchase-requisition/view",
    meta: viewbvik7ZP5WgMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/purchase-requisition/view.vue")
  },
  {
    name: "purchases-suppliers-add",
    path: "/purchases/suppliers/add",
    meta: addxTaATomEYCMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/add.vue")
  },
  {
    name: "purchases-suppliers-edit",
    path: "/purchases/suppliers/edit",
    meta: edittmjY1bg5ZqMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/edit.vue")
  },
  {
    name: "purchases-suppliers",
    path: "/purchases/suppliers",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/index.vue")
  },
  {
    name: "purchases-suppliers-view",
    path: "/purchases/suppliers/view",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/purchases/suppliers/view.vue")
  },
  {
    name: "sales-calculator-add",
    path: "/sales/calculator/add",
    meta: addaKRYZxSs3HMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/calculator/add.vue")
  },
  {
    name: "sales-calculator",
    path: "/sales/calculator",
    meta: indexgINusafPObMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/calculator/index.vue")
  },
  {
    name: "sales-customer-add",
    path: "/sales/customer/add",
    meta: addCz8bVRTW5HMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/customer/add.vue")
  },
  {
    name: "sales-customer-edit",
    path: "/sales/customer/edit",
    meta: edit7QZunEpjYaMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/customer/edit.vue")
  },
  {
    name: "sales-customer",
    path: "/sales/customer",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/customer/index.vue")
  },
  {
    name: "sales-customer-view",
    path: "/sales/customer/view",
    meta: viewCRmsn1hJ6qMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/customer/view.vue")
  },
  {
    name: "sales-invoice-add",
    path: "/sales/invoice/add",
    meta: addrOLPEAaiv3Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/add.vue")
  },
  {
    name: "sales-invoice-edit",
    path: "/sales/invoice/edit",
    meta: editvTh8SxbXWYMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/edit.vue")
  },
  {
    name: "sales-invoice",
    path: "/sales/invoice",
    meta: indexx7IR4hyYHUMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/index.vue")
  },
  {
    name: "sales-invoice-view",
    path: "/sales/invoice/view",
    meta: viewDgQVVcxZjiMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/invoice/view.vue")
  },
  {
    name: "sales-quotation-add",
    path: "/sales/quotation/add",
    meta: addxJjl4OmqmjMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/add.vue")
  },
  {
    name: "sales-quotation-edit",
    path: "/sales/quotation/edit",
    meta: editV2nmlAhfF9Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/edit.vue")
  },
  {
    name: "sales-quotation",
    path: "/sales/quotation",
    meta: indexz4G3ZklhSkMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/index.vue")
  },
  {
    name: "sales-quotation-view",
    path: "/sales/quotation/view",
    meta: viewY3N2dDDjvEMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/quotation/view.vue")
  },
  {
    name: "sales-spec-sheet-add",
    path: "/sales/spec-sheet/add",
    meta: add8q6CXubLhOMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/add.vue")
  },
  {
    name: "sales-spec-sheet-edit",
    path: "/sales/spec-sheet/edit",
    meta: edit1HSnSjNDbQMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/edit.vue")
  },
  {
    name: "sales-spec-sheet",
    path: "/sales/spec-sheet",
    meta: indexb9cK3QAkz2Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/index.vue")
  },
  {
    name: "sales-spec-sheet-view",
    path: "/sales/spec-sheet/view",
    meta: indext3e5kH4DVGMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/view/index.vue")
  },
  {
    name: "sales-spec-sheet-view-print",
    path: "/sales/spec-sheet/view/print",
    meta: printmVutMDYM0WMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/sales/spec-sheet/view/print.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/settings/index.vue")
  },
  {
    name: "settings-system-department",
    path: "/settings/system/department",
    meta: departmentWnO3AmihTUMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/settings/system/department.vue")
  },
  {
    name: "settings-system-dropdown-data",
    path: "/settings/system/dropdown-data",
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/settings/system/dropdown-data.vue")
  },
  {
    name: "settings-system-prod-users",
    path: "/settings/system/prod-users",
    meta: prod_45users8D8U31jrx2Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/settings/system/prod-users.vue")
  },
  {
    name: "settings-system-setting-system",
    path: "/settings/system/setting-system",
    meta: setting_45systemrp9BH1Q2FQMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/settings/system/setting-system.vue")
  },
  {
    name: "settings-system-users",
    path: "/settings/system/users",
    meta: usersVyxJ905YjoMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/settings/system/users.vue")
  },
  {
    name: "store-adjustment-add",
    path: "/store/adjustment/add",
    meta: addyEu2cO9OcmMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/add.vue")
  },
  {
    name: "store-adjustment-edit",
    path: "/store/adjustment/edit",
    meta: editrhlZiclpQmMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/edit.vue")
  },
  {
    name: "store-adjustment",
    path: "/store/adjustment",
    meta: indexnOYlJCzP8QMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/index.vue")
  },
  {
    name: "store-adjustment-view",
    path: "/store/adjustment/view",
    meta: viewNDxV2tpdJoMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/adjustment/view.vue")
  },
  {
    name: "store-material-delivery-add",
    path: "/store/material-delivery/add",
    meta: addXLs837VHI1Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/add.vue")
  },
  {
    name: "store-material-delivery-edit",
    path: "/store/material-delivery/edit",
    meta: editKUBstGUZJKMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/edit.vue")
  },
  {
    name: "store-material-delivery",
    path: "/store/material-delivery",
    meta: index8IpgGAfSM9Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/index.vue")
  },
  {
    name: "store-material-delivery-view",
    path: "/store/material-delivery/view",
    meta: viewoiBXPRSPW6Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-delivery/view.vue")
  },
  {
    name: "store-material-request-add",
    path: "/store/material-request/add",
    meta: addUTOfZDP8tbMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-request/add.vue")
  },
  {
    name: "store-material-request-edit",
    path: "/store/material-request/edit",
    meta: editHHM1XPknMjMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-request/edit.vue")
  },
  {
    name: "store-material-request",
    path: "/store/material-request",
    meta: indexrbSRNwvLzFMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-request/index.vue")
  },
  {
    name: "store-material-request-view",
    path: "/store/material-request/view",
    meta: viewGOSFnx5zISMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-request/view.vue")
  },
  {
    name: "store-material-summary-add",
    path: "/store/material-summary/add",
    meta: addTOf9JVzYLmMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/add.vue")
  },
  {
    name: "store-material-summary-edit",
    path: "/store/material-summary/edit",
    meta: edit7z7UOFhCktMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/edit.vue")
  },
  {
    name: "store-material-summary",
    path: "/store/material-summary",
    meta: indexJvfs2ZbtNPMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/index.vue")
  },
  {
    name: "store-material-summary-view",
    path: "/store/material-summary/view",
    meta: viewoulj2IstuwMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-summary/view.vue")
  },
  {
    name: "store-material-surplus-return-add",
    path: "/store/material-surplus-return/add",
    meta: addP3udjEcyQOMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/add.vue")
  },
  {
    name: "store-material-surplus-return-edit",
    path: "/store/material-surplus-return/edit",
    meta: editRWrEzRq5yKMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/edit.vue")
  },
  {
    name: "store-material-surplus-return",
    path: "/store/material-surplus-return",
    meta: indexRZ0X45vzLiMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/index.vue")
  },
  {
    name: "store-material-surplus-return-view",
    path: "/store/material-surplus-return/view",
    meta: viewOQk9fThjBbMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/material-surplus-return/view.vue")
  },
  {
    name: "store-product-category",
    path: "/store/product-category",
    meta: indexAZ8YodfQqSMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/product-category/index.vue")
  },
  {
    name: "store-product-add",
    path: "/store/product/add",
    meta: addiJ6tfTH7NbMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/product/add.vue")
  },
  {
    name: "store-product",
    path: "/store/product",
    meta: indexXZq1szYcjgMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/product/index.vue")
  },
  {
    name: "store-product-view",
    path: "/store/product/view",
    meta: view8VDt1OhKjtMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/product/view.vue")
  },
  {
    name: "store-recieve-product-add",
    path: "/store/recieve-product/add",
    meta: add084IMo5430Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/recieve-product/add.vue")
  },
  {
    name: "store-recieve-product",
    path: "/store/recieve-product",
    meta: indexdpogRI9kU5Meta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/recieve-product/index.vue")
  },
  {
    name: "store-recieve-product-view",
    path: "/store/recieve-product/view",
    meta: viewyJ1lyQVrLkMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/recieve-product/view.vue")
  },
  {
    name: "store-store-type",
    path: "/store/store-type",
    meta: indexkdwAllAMpXMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/store-type/index.vue")
  },
  {
    name: "store-wareHouse-add",
    path: "/store/wareHouse/add",
    meta: addwHEywFhJqaMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/wareHouse/add.vue")
  },
  {
    name: "store-wareHouse",
    path: "/store/wareHouse",
    meta: indexydZyMBxuBZMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/wareHouse/index.vue")
  },
  {
    name: "store-wareHouse-view",
    path: "/store/wareHouse/view",
    meta: viewBJEouXQ6eUMeta || {},
    component: () => import("/home/runner/work/texcel-web/texcel-web/pages/store/wareHouse/view.vue")
  }
]