<script lang="ts" setup>
import type { PackingList } from '~/types/PackingList';
import type { SpecSheet } from '~/types/SpecSheet';

// PROPS
interface Props {
    specSheet: SpecSheet;
    packingList?: PackingList;
}
const props = defineProps<Props>();
const { specSheet } = toRefs(props);
</script>

<template>
    <div class="bg-white dark:bg-stone-800 rounded-xl p-5">
        <h2 class="dark:text-white font-medium text-2xl">ใบสเปค: {{ specSheet.name }}</h2>

        <template v-if="packingList">
            <h3 class="dark:text-white text-xl">{{ packingList?.name }}</h3>
            <p class="text-stone-500 text-lg">{{ packingList?.group?.name }}</p>
        </template>
    </div>
</template>
