<script lang="ts" setup>
// PROPS
interface Props {
    disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), { disabled: false });
const { disabled } = toRefs(props);
</script>
<template>
    <button class="bg-primary-500/20 p-5 rounded-xl border-4 border-primary-500 border-dashed text-white text-2xl font-medium cursor-pointer" :disabled="disabled">เพิ่ม Pack</button>
</template>
