import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

export const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-xl text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-stone-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-stone-950 dark:focus-visible:ring-stone-300',
    {
        variants: {
            variant: {
                default: 'bg-primary-500 text-white hover:bg-primary-600',
                destructive: 'bg-red-500 text-stone-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-stone-50 dark:hover:bg-red-900/90',
                outline:
                    'border text-stone-700 dark:text-white border-stone-200 bg-white hover:bg-stone-100 hover:text-stone-900 dark:border-stone-800 dark:bg-stone-950 dark:hover:bg-stone-800 dark:hover:text-stone-50',
                secondary: 'bg-stone-200 text-stone-900 hover:bg-stone-200/80 dark:bg-stone-800 dark:text-stone-50 dark:hover:bg-stone-800/80',
                secondaryLight: 'bg-stone-100 text-stone-900 hover:bg-stone-100/80 dark:bg-stone-700 dark:text-stone-50 dark:hover:bg-stone-700/80',
                'secondary-900': 'bg-stone-300 text-stone-950 hover:bg-stone-300/80 dark:bg-stone-900 dark:text-stone-100 dark:hover:bg-stone-900/80',
                ghost: 'text-stone-500 dark:text-stone-500 hover:bg-stone-100 hover:text-stone-900 dark:hover:bg-stone-800 dark:hover:text-stone-50',
                link: 'text-stone-900 underline-offset-4 hover:underline dark:text-stone-50',
                peak: 'bg-blue-500 text-white hover:bg-blue-500/80',
                sky: 'bg-sky-500 text-white hover:bg-sky-500/80',
                yellow: 'bg-yellow-500 text-white hover:bg-yellow-500/80',
                success: 'text-white bg-green-500 hover:bg-green-600',
                secondaryLight: 'bg-stone-200 text-stone-900 hover:bg-stone-200/80 dark:bg-stone-700 dark:text-stone-50 dark:hover:bg-stone-700/80',
            },
            size: {
                default: 'h-10 px-4 py-2',
                xs: 'h-7 rounded px-2',
                sm: 'h-9 rounded-xl px-3',
                lg: 'h-11 rounded-xl px-8',
                icon: 'h-10 w-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
