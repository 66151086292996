import cloneDeep from 'lodash/cloneDeep';
import type { PackData, PackDataInfo, PackingList } from '~/types/PackingList';
import type { SpecSheetAmountInner } from '~/types/SpecSheet';

// CONSTANT
const MAX_QUANTITY_PER_PACK = 50;
const ACCEPTANCE_OVER_QUANTITY = 20;
let packDatas: PackData[] = [];
let subPackData: PackData = {
    id: '',
    info: [],
    totalQuantity: 0,
};

// FUNCTION
function initFunction() {
    packDatas = [];
    resetPackData();
}

function resetPackData() {
    subPackData = cloneDeep({
        id: useGenVueKey(),
        info: [],
        totalQuantity: 0,
    });
}

function addSizeToPackInfo(sizeData: SpecSheetAmountInner, amountGroupId: string) {
    const payload: PackDataInfo = {
        id: useGenVueKey(),
        amountGroupId: amountGroupId,
        sizeId: sizeData.id,
        sizeName: sizeData.name,
        quantity: sizeData.amount,
    };
    subPackData.info.push(payload);

    subPackData.totalQuantity += payload.quantity;
}

function addSubPackToPack() {
    packDatas.push(subPackData);
    resetPackData();
}

function mergeLastPack() {
    const totalPack = packDatas.length;
    const lastPackIndex = totalPack - 1;
    const lastPack = packDatas[lastPackIndex];
    const haveMore1Pack = totalPack > 1;

    if (haveMore1Pack && lastPack.totalQuantity <= ACCEPTANCE_OVER_QUANTITY) {
        const secondaryLastPack = packDatas[lastPackIndex - 1];

        secondaryLastPack.info.push(...lastPack.info);
    }
}

export default (packingList: PackingList) => {
    initFunction();

    const amountData = cloneDeep(packingList.amountData);

    for (const amount of amountData) {
        for (const size of amount.info) {
            while (size.amount !== 0) {
                const newTotalQuantity = subPackData.totalQuantity + size.amount;

                if (newTotalQuantity <= MAX_QUANTITY_PER_PACK) {
                    addSizeToPackInfo(size, amount.name);
                    size.amount = 0;
                } else {
                    const remainingPackQuantity = MAX_QUANTITY_PER_PACK - subPackData.totalQuantity;
                    addSizeToPackInfo({ ...size, amount: remainingPackQuantity }, amount.name);

                    // SET REMAINING SIZE AMOUNT
                    size.amount = size.amount - remainingPackQuantity;
                }

                if (subPackData.totalQuantity === MAX_QUANTITY_PER_PACK) {
                    addSubPackToPack();
                }
            }
        }
    }

    mergeLastPack();

    if (subPackData.totalQuantity > 0) {
        addSubPackToPack();
    }

    return packDatas;
};
