<script lang="ts" setup>
import PackList from '~/components/production/packingList/view/packing/AllPackList.vue';
import PackingListSidebar from '~/components/production/packingList/view/sideBar/PackingListSidebar.vue';
import PackingQuantity from '~/components/production/packingList/view/sideBar/PackingQuantity.vue';
import ActionButton from '~/components/production/packingList/view/sideBar/ActionButton.vue';
import type { PackData, PackingList } from '~/types/PackingList';
import NotSelectPackingList from '~/components/production/packingList/view/NotSelectPackingList.vue';
import PackingDetail from '~/components/production/packingList/view/packing/PackingDetail.vue';
import type { SpecSheet, SpecSheetAmountInner } from '~/types/SpecSheet';
import type AllPackList from '~/components/production/packingList/view/packing/AllPackList.vue';

// META
const specSheetId = useRoute().query['spec-sheet-id'] as string;

useHead({
    title: `Packing List`,
});

definePageMeta({
    validate: (route) => !!route.query['spec-sheet-id'],
});

// EL INSTANCE
const allPackListInstance = ref<InstanceType<typeof AllPackList>>();

// VARIABLE
const specSheetService = useSpecSheet();
const size = ref<SpecSheetAmountInner>({} as SpecSheetAmountInner); // Define the size property
const isLoading = ref<boolean>(true);
const specSheet = ref<SpecSheet>({ name: '' } as SpecSheet);
const activePackingList = ref<PackingList | undefined>(undefined);

// FUNCTION
function throwErrorWhenNotFound() {
    throw createError({ statusCode: 404, statusText: 'ไม่พบข้อมูลใบสเปค' });
}

async function getSpecSheet() {
    const data = await specSheetService.getById(specSheetId, {}, { errorCallback: throwErrorWhenNotFound });

    if (data) {
        return data;
    } else {
        throwErrorWhenNotFound();
    }
}

function setTitle() {
    useHead({
        title: `Packing List - ${specSheet.value.name}`,
    });
}

function setActivePackingList(packingList: PackingList) {
    activePackingList.value = packingList;
    if (allPackListInstance.value) {
        allPackListInstance.value.setNewPackingList(activePackingList.value);
    }
}

function updatePackData(packDatas: PackData[]) {
    if (allPackListInstance.value) {
        allPackListInstance.value.form.setFieldValue('packData', packDatas);
        allPackListInstance.value.update();
    }
}

// ONMOUNTED
onMounted(async () => {
    specSheet.value = (await getSpecSheet()) as SpecSheet;
    setTitle();
    isLoading.value = false;
});
</script>

<template>
    <NuxtLayout page-name="Packing Lists">
        <div v-if="!isLoading" class="grid gap-4 flex-col sm:flex-row md:gap-4 xl:grid-cols-12">
            <div class="col-span-12 order-1 sm:col-span-12 sm:order-1 md:col-span-12 md:order-1 xl:col-span-3 xl:order-1">
                <div class="md:sticky md:top-5 space-y-5">
                    <PackingListSidebar :spec-sheet="specSheet" @select="setActivePackingList" />
                    <PackingQuantity v-if="activePackingList" :packing-list="activePackingList" />
                    <ActionButton v-if="activePackingList" :packing-list="activePackingList" @split-pack="(packDatas) => updatePackData(packDatas)" />
                </div>
            </div>

            <div class="col-span-12 order-2 sm:col-span-12 sm:order-2 md:col-span-12 md:order-2 xl:col-span-9 xl:order-2">
                <PackingDetail class="mb-5" :packing-list="activePackingList" :spec-sheet="specSheet" />
                <template v-if="activePackingList">
                    <PackList
                        ref="allPackListInstance"
                        :size="size"
                        :packing-list="activePackingList"
                        @updated="
                            (packingList) => {
                                activePackingList = packingList;
                            }
                        "
                    />
                </template>

                <NotSelectPackingList v-else />
            </div>
        </div>

        <LoadingScreen v-else />
    </NuxtLayout>
</template>
