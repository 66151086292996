<script lang="ts" setup>
import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '@/utils/jspdfFont/custom-font';
import type { PackData, PackingList } from '~/types/PackingList';
import autoSplitPack from '~/Functions/PackingList/autoSplitPack';
import packingListDocs from '~/Functions/PackingList/packingListDocs';
import stickerListDocs from '~/Functions/PackingList/stickerListDocs';

// PROPS
interface Props {
    packingList: PackingList;
}
const props = defineProps<Props>();
const { packingList } = toRefs(props);

// EMIT
type Emit = {
    splitPack: [packDatas: PackData[]];
};
const emit = defineEmits<Emit>();

// FUNCTION
function initPdfMake() {
    window.pdfMake.vfs = pdfFonts;
    window.pdfMake.fonts = {
        IBMPlexSansThai: {
            normal: 'IBMPlexSansThai-Regular.ttf',
            bold: 'IBMPlexSansThai-Bold.ttf',
        },
    };
}

const generatePdf = async () => {
    const payload = await packingListDocs(packingList.value);
    pdfMake.createPdf(payload as any).open();
};

const generateSticker = () => {
    pdfMake.createPdf(stickerListDocs(packingList.value) as any).open();
};

function autoPack() {
    emit('splitPack', autoSplitPack(packingList.value));
}

// ONMOUNTED
onMounted(() => {
    initPdfMake();
});
</script>

<template>
    <div class="space-y-3">
        <Button class="w-full text-lg" @click="autoPack()">แบ่งแพ็กอัตโนมัติ</Button>
        <Button class="w-full text-lg" variant="secondaryLight" @click="generatePdf()"> พิมพ์เอกสาร Packing Lists </Button>
        <Button class="w-full text-lg" variant="secondaryLight" @click="generateSticker()"> พิมพ์สติกเกอร์ประทับถุง </Button>
    </div>
</template>
