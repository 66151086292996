<script lang="ts" setup>
import type { PackingList } from '~/types/PackingList';

// PROPS
interface Props {
    packingList: PackingList;
}
const props = defineProps<Props>();
const { packingList } = toRefs(props);
</script>

<template>
    <div class="bg-white dark:bg-stone-800 rounded-xl p-4">
        <h2 class="text-xl text-black dark:text-white font-medium mb-3">จำนวนทั้งหมดใน Packing List นี้</h2>

        <div class="space-y-5">
            <div v-for="amount in packingList.amountData" :key="amount.id">
                <p class="dark:text-stone-300 font-medium mb-1">ชื่อกลุ่ม: {{ amount.name }}</p>
                <div class="relative overflow-auto border border-stone-200 dark:border-stone-500 rounded-xl">
                    <table class="w-full table-fixed text-left">
                        <thead>
                            <tr class="bg-white dark:bg-stone-700 dark:text-white">
                                <th class="px-6 py-3 w-[50%] border-r border-stone-200 dark:border-stone-500 border-b">ไซซ์</th>
                                <th class="px-6 py-3 w-[50%] border-b border-stone-200 dark:border-stone-500">จำนวน(ตัว)</th>
                            </tr>
                        </thead>

                        <tbody class="bg-white dark:bg-stone-700">
                            <tr v-for="size in amount.info" :key="size.id" class="text-black dark:text-white text-left border-b last:border-b-0 border-stone-200 dark:border-stone-500">
                                <td class="w-[50%] px-6 py-3 border-r border-stone-200 dark:border-stone-500">{{ size.name }}</td>
                                <td class="w-[50%] px-6 py-3">{{ size.amount }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
