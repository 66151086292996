<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import type { FormContext } from 'vee-validate';
import type { SpecSheetAmount } from '~/types/SpecSheet';
import type { MultiselectGroupOptions } from '~/types/Global';

// PROPS
interface Props {
    amountData: SpecSheetAmount[];
    disabled?: boolean;
    form: FormContext;
    formPrefix?: string;
}
const props = withDefaults(defineProps<Props>(), { disabled: false, formPrefix: '' });
const { form, amountData, formPrefix } = toRefs(props);

//EMIT
type Emit = {
    delete: [];
    change: [];
};
const emit = defineEmits<Emit>();

const deleteSize = () => emit('delete');

//COMPUTED
const sizeOptions = computed<MultiselectGroupOptions[]>(() => {
    return amountData.value.map((group) => ({
        label: group.name,
        options: group.info.map((size) => ({ label: size.name, value: size.name, amountGroupId: group.id, sizeId: size.id })),
    }));
});

function formPath(path: string): string {
    if (!formPrefix.value) {
        return path;
    }
    return `${formPrefix.value}.${path}`;
}
</script>

<template>
    <div class="grid grid-cols-12 gap-3 bg-stone-200 dark:bg-stone-700 rounded-xl p-3">
        <div class="col-span-6 xl:col-span-5 sm:col-span-6 md:col-span-6 lg:col-span-5">
            <Label> ไซซ์ </Label>
            <FormField :name="formPath('sizeName')" #="{ componentField }">
                <FormItem>
                    <FormControl>
                        <multiselect
                            v-bind="componentField"
                            mode="single"
                            :groups="true"
                            :options="sizeOptions"
                            class="text-white bg-stone-600 w-full"
                            :classes="$multiselectClass"
                            placeholder="--ไซซ์--"
                            @select="
                                (value, option) => {
                                    form.setFieldValue(formPath('sizeId'), option.sizeId);
                                    form.setFieldValue(formPath('amountGroupId'), option.amountGroupId);
                                    $emit('change');
                                }
                            "
                        >
                        </multiselect>
                    </FormControl>
                </FormItem>
            </FormField>
        </div>

        <div class="col-span-6 xl:col-span-5 sm:col-span-6 md:col-span-6 lg:col-span-5">
            <Label> จำนวน </Label>
            <FormField :name="formPath('quantity')" #="{ componentField }">
                <FormItem>
                    <FormControl>
                        <Input v-bind="componentField" :disabled="disabled" :form="form" size="sm" type="number" min="0" placeholder="--จำนวน--" @input="$emit('change')" />
                    </FormControl>
                </FormItem>
            </FormField>
        </div>

        <div class="col-span-12 xl:col-span-2 sm:col-span-12 md:col-span-12 lg:col-span-2 flex items-end">
            <Button class="w-full p-1" variant="destructive" @click="deleteSize">
                <IconCSS name="material-symbols:delete-rounded" size="1.5rem" />
            </Button>
        </div>
    </div>
</template>
